import React from "react"

type PlatformProps = {
  android: string
  ios: string
  pc: string
}

const Platform = ({ android, ios, pc }: PlatformProps) => {
  if (android !== `` && ios === `` && pc === ``) {
    return (
      <div style={{ marginTop: `7%` }}>
        <h2>Platforms:</h2>

        <div>
          <h3>
            Android:{` `}
            <a href={android} style={textColor}>
              {` `}
              Google Play Store Link{` `}
            </a>
          </h3>
        </div>
      </div>
    )
  }

  if (android === `` && ios !== `` && pc === ``) {
    return (
      <div style={{ marginTop: `7%` }}>
        <h2>Platforms:</h2>

        <div>
          <h3>
            IOS:{` `}
            <a href={ios} style={textColor}>
              {` `}
              Apple Store Link{` `}
            </a>
          </h3>
        </div>
      </div>
    )
  }

  if (android === `` && ios === `` && pc !== ``) {
    return (
      <div style={{ marginTop: `7%` }}>
        <h2>Platforms:</h2>

        <div>
          <h3>PC:</h3>
          <p>{pc}</p>
        </div>
      </div>
    )
  }

  if (android !== `` && ios !== `` && pc === ``) {
    return (
      <div style={{ marginTop: `7%` }}>
        <h2>Platforms:</h2>

        <div>
          <h3>
            Android:{` `}
            <a href={android} style={textColor}>
              {` `}
              Google Play Store Link{` `}
            </a>
          </h3>
        </div>

        <div style={{ marginTop: `10%` }}>
          <h3>
            IOS:{` `}
            <a href={ios} style={textColor}>
              {` `}
              Apple Store Link{` `}
            </a>
          </h3>
        </div>
      </div>
    )
  }

  if (android !== `` && ios !== `` && pc !== ``) {
    return (
      <div style={{ marginTop: `7%` }}>
        <h2>Platforms:</h2>

        <div>
          <h3>PC:</h3>
          <p>{pc}</p>
        </div>

        <div style={{ marginTop: `10%` }}>
          <h3>
            Android:{` `}
            <a href={android} style={textColor}>
              {` `}
              Google Play Store Link{` `}
            </a>
          </h3>
        </div>

        <div style={{ marginTop: `10%` }}>
          <h3>
            IOS:{` `}
            <a href={ios} style={textColor}>
              {` `}
              Apple Store Link{` `}
            </a>
          </h3>
        </div>
      </div>
    )
  }

  if (android !== `` && ios === `` && pc !== ``) {
    return (
      <div style={{ marginTop: `7%` }}>
        <h2>Platforms:</h2>

        <div>
          <h3>PC:</h3>
          <p>{pc}</p>
        </div>

        <div style={{ marginTop: `10%` }}>
          <h3>
            Android:{` `}
            <a href={android} style={textColor}>
              {` `}
              Google Play Store Link{` `}
            </a>
          </h3>
        </div>
      </div>
    )
  }

  if (android === `` && ios !== `` && pc !== ``) {
    return (
      <div style={{ marginTop: `7%` }}>
        <h2>Platforms:</h2>

        <div>
          <h3>PC:</h3>
          <p>{pc}</p>
        </div>

        <div style={{ marginTop: `10%` }}>
          <h3>
            IOS:{` `}
            <a href={ios} style={textColor}>
              {` `}
              Apple Store Link{` `}
            </a>
          </h3>
        </div>
      </div>
    )
  }

  if (android === `` && ios === `` && pc === ``) {
    return <div />
  }
}

const textColor = {
  color: `#D87BA2`,
}

export default Platform
