import React from "react"
import ImageGallery from "react-image-gallery"
import { ChildImageSharp } from "../types"

import "react-image-gallery/styles/css/image-gallery.css"

type GalleryImage = {
  original: ChildImageSharp
  thumbnail: ChildImageSharp
  originalTitle: string
  thumbnailTitle: string
  originalAlt: string
  thumbnailAlt: string
  videoURL: string
  videoWidth: number
  videoHeight: number
}

const ImageGalleryRenderer = ({ galleryImages }: [GalleryImage]) => {
  const renderVideo = (item: any) => (
    <iframe
      title="video"
      width={item.videoWidth}
      height={item.videoHeight}
      src={item.embedUrl}
      frameBorder="0"
      allowFullScreen
    />
  )

  const generateGalleryData = () => {
    const galleryData = []

    for (let i = 0; i < galleryImages.length; i++) {
      if (galleryImages[i].videoURL !== null) {
        galleryData.push({
          original: galleryImages[i].original.childImageSharp.fluid.src,
          thumbnail: galleryImages[i].thumbnail.childImageSharp.fluid.src,
          originalTitle: galleryImages[i].originalTitle,
          thumbnailTitle: galleryImages[i].thumbnailTitle,
          originalAlt: galleryImages[i].originalAlt,
          thumbnailAlt: galleryImages[i].thumbnailAlt,
          embedUrl: galleryImages[i].videoURL,
          videoWidth: galleryImages[i].videoWidth,
          videoHeight: galleryImages[i].videoHeight,
          renderItem: renderVideo,
        })
      } else {
        galleryData.push({
          original: galleryImages[i].original.childImageSharp.fluid.src,
          thumbnail: galleryImages[i].thumbnail.childImageSharp.fluid.src,
          originalTitle: galleryImages[i].originalTitle,
          thumbnailTitle: galleryImages[i].thumbnailTitle,
          originalAlt: galleryImages[i].originalAlt,
          thumbnailAlt: galleryImages[i].thumbnailAlt,
          embedUrl: null,
          renderItem: null,
        })
      }
    }

    return galleryData
  }

  const gData = generateGalleryData()

  return (
    <div>
      <ImageGallery items={gData} lazyLoad />
    </div>
  )
}

export default ImageGalleryRenderer
